export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const CONFIRMATION_ROUTE = '/confirmation';
export const PLUGINS_ROUTE = '/plugins';
export const SERVERS_ROUTE = '/servers';
export const SERVER_PLUGINS_ROUTE = '/server/plugins';
export const CREATE_SERVER = '/server/create';
export const CHAT_ROUTE = '/chat';
export const LOG_ROUTE = '/log';
export const SERVER_DETAILS = '/details';
export const CHAT_PAGE = '/chatpage';
export const CONFIG_EDIT = '/configedit';
