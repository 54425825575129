import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '..';
import jwt_decode from 'jwt-decode';
import { $authHost } from './index'; // Подключаем $authHost для работы с API

let socketInstance = null; // Singleton для WebSocket

class WebSocketService {
    constructor() {
        this.socket = null;
    }

    setupWebSocket(token, user) {
        if (this.socket) {
            this.socket.close(); // Закрываем существующее соединение
        }

        this.socket = new WebSocket(`wss://scraplands.ru:4093?token=${token}`);

        this.socket.onopen = () => {
            console.log('Соединение установлено');
        };

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === "ping") {
                this.socket.send(JSON.stringify({ type: "pong" }));
            } else if (data && data.chat) {
                const newChatData = {
                    chat: data.chat,
                    data: [data.data],
                };
                user.setChat(newChatData);
            }
        };

        this.socket.onclose = () => {
            console.log('Соединение закрыто');
            setTimeout(() => this.reconnect(user), 5000); // Переподключаемся через 5 секунд
        };

        this.socket.onerror = async (error) => {
            console.error('Ошибка WebSocket', error);

            if (error.message.includes('401')) {
                try {
                    const { data } = await $authHost.post('api/v1/refresh.php', {}, { withCredentials: true });
                    const newToken = data.access_token;
                    localStorage.setItem('token', newToken);
                    this.setupWebSocket(newToken, user);
                } catch (refreshError) {
                    console.error('Ошибка обновления токена', refreshError);
                }
            }
        };
    }

    reconnect(user) {
        const token = localStorage.getItem('token');
        if (token) {
            this.setupWebSocket(token, user);
        } else {
            console.log('Токен не найден, переподключение невозможно');
        }
    }
}

export const getWebSocketInstance = (user) => {
    if (!socketInstance) {
        socketInstance = new WebSocketService();
    }
    return socketInstance;
};

const WsUserAPI = observer(() => {
    const { user } = useContext(Context);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const webSocket = getWebSocketInstance(user);
            webSocket.setupWebSocket(token, user);
        }

        return () => {
            if (socketInstance?.socket) {
                socketInstance.socket.close();
            }
        };
    }, [user]);

    return null;
});

export default WsUserAPI;
