import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";

const ConfigEdit = () => {
    const [jsonData, setJsonData] = useState(null);
    const [editedData, setEditedData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = {
                    "settings": {
                        "Tabs": [
                            {
                                "ButtonText": "<color=#FFFFFF>Описание </color>",
                                "HeaderText": "<color=#FFFFD7>Информация о сервере. /info</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "",
                                            "<color=#FFFFFF>Приветствую друг на нашем сервере!</color><color=#F0E650> [Ru] PVE ☢ScrapLands☢</color>",
                                            "<color=#F7A934>X2-X4 | TP | крафт переработчика | личные карьеры | Скины | RPG система прокачек | NPC | Спутник</color>",
                                            "<color=#F7A934>Рюкзак | Конвой | рейд базы | вызов вертолета | мгновенный крафт | личный миникоптер | автосвет | киты | Sil</color>",
                                            "<color=#F7A934>Быстрая посадка растений | растения не гниют | замки на вертолеты и машины | замки на печи и карьеры | Магазин</color>",
                                            "<color=#FFFFFF>На сервер по мере возможностей вносятся изменения, которые будут обновляться в описании.</color>",
                                            "<color=#FFFFFF>Уважайте администрацию и других игроков и проблем у вас не будет.</color>",
                                            "<color=#F7A934>Присоединяйтесь к нам в Дискорде <color=#00FF00>YVfPvvm2dc</color></color>",
                                            "<color=#FFFFFF><color=#FF0000>Кража имущества игроков</color> в период действия ПВЕ <color=#FF0000>наказуема</color>, пункт 4.4 правил</color>",
                                            "<color=#00FF00>Обязательно ставьте замки на ящики, двери, печи, карьеры чтоб защитить свое имущество.</color>",
                                            "<color=#F7A934>Получите БЕСПЛАТНО ВИП новичку на неделю и другие бесплатные подарки в нашем совместном магазине</color>",
                                            "<color=#F7A934>Заходите в магазин <color=#00FF00>OVERLIVE.RU</color> и выбирайте вкладку <color=#00FF00>ScrapLands</color></color>",
                                            "<color=#6EE185>------------------------------------------------------------------------------------------------------------</color>",
                                            "<color=#F0E650> Краткое описание сервера:</color>",
                                            "<color=#FFFFFF> ► Проект основан - 06.07.2023.</color>",
                                            "<color=#FFFFFF> ► Вайп - раз в месяц (вайп был 07.03.2024, следующий 04.04.2024 примерно в 20 - 22 часа по МСК)</color>",
                                            "<color=#FFFFFF> ► ПВП режим в последние 2 дня перед вайпом 03 апреля и 04 апреля</color>",
                                            "<color=#FFFFFF> ► Сервер: srv.overlive.ru Порт: 35000</color>",
                                            "<color=#FFFFFF> ► Крата - Procedural (4250)</color>",
                                            "<color=#FFFFFF> ► Рестарт - в 5 утра МСК.</color>",
                                            "<color=#FFFFFF> ► ПВП зоны на монументах.</color>",
                                            "<color=#6EE185>------------------------------------------------------------------------------------------------------------</color>",
                                            "<color=#F7A934> ☭☭☭ ВЕДЕТСЯ МОДЕРНИЗАЦИЯ И ВНЕДРЕНИЕ НОВЫХ ВОЗМОЖНОСТЕЙ. ☭☭☭ </color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Награды Топерам</color>",
                                "HeaderText": "<color=#FFFFD7>Награды победителям топ 3.</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "",
                                            "Награды зачисляются после вайпа, RP на баланс во внутреннем магазине, ",
                                            "бонусные рубли на баланс магазина overlive.ru, которые можно тратить на двух серверах",
                                            "",
                                            "Наименование | <color=#ff7f01>1 место</color> | <color=#84ff01>2 место</color> | <color=#de01ff>3 место</color>",
                                            "",
                                            "Топ по очкам | <color=#ff7f01>500RP и 50 бонусов</color> | <color=#84ff01>300RP и 30 бонусов</color> | <color=#de01ff>100RP и 10 бонусов</color>",
                                            "Топ фармеры | <color=#ff7f01>300RP и 30 бонусов</color> | <color=#84ff01>200RP и 20 бонусов</color> | <color=#de01ff>100RP и 10 бонусов</color>",
                                            "Топ рейдеры | <color=#ff7f01>300RP и 30 бонусов</color> | <color=#84ff01>200RP и 20 бонусов</color> | <color=#de01ff>100RP и 10 бонусов</color>",
                                            "Топ онлайн    | <color=#ff7f01>300RP и 30 бонусов</color> | <color=#84ff01>200RP и 20 бонусов</color> | <color=#de01ff>100RP и 10 бонусов</color>",
                                            "Топ убийца NPC | <color=#ff7f01>300RP и 30 бонусов</color> | <color=#84ff01>200RP и 20 бонусов</color> | <color=#de01ff>100RP и 10 бонусов</color>",
                                            "Топ охотник   | <color=#ff7f01>300RP и 30 бонусов</color> | <color=#84ff01>200RP и 20 бонусов</color> | <color=#de01ff>100RP и 10 бонусов</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Возможности </color>",
                                "HeaderText": "<color=#FFFFD7>Возможности для игроков. </color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Телепорт - 20 в день</color> <color=#00FF00>на ВИП - 100</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Точки дома - 2</color> <color=#00FF00>на ВИП - 5</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Нефтяные платформы, рандомные боты и т.д.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Ивент Спутник</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Рейты X2-X4(только ресурсы).</color> <color=#00FF00>ВИП дополнительно X2</color>",
                                            "<color=#65DE39> ✓ </color><color=#00FF00>Покупка ВИП в магазине overlive.ru, новичкам ВИП на неделю БЕСПЛАТНО.</color>",
                                            "<color=#65DE39> ✓ </color><color=#00FF00>ВИП - Вызов вертолета 3 раза в день.</color>",
                                            "<color=#65DE39> ✓ </color><color=#00FF00>ВИП - Личный миникоптер.</color>",
                                            "<color=#65DE39> ✓ </color><color=#00FF00>ВИП - Можно засаживать всю грядку одним кликом, зажимая SHIFT.</color>",
                                            "<color=#65DE39> ✓ </color><color=#00FF00>ВИП - Отключена гибель растений от гниения, обязателен полив и свет.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Ивент в аэропорту.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Рейд Базы.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Возможность покупки личной Рейд Базы за RP.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Покупка реактивного ранца в магазине overlive.ru.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Покупка неломайки оружия и инструмента в магазине overlive.ru.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Управление генетикой растений в магазине overlive.ru.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Установка замков на миникоптер и другой транспорт.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Разные виды конвоя. Нападите и перехватите груз.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Увеличены стаки.(в предметах хранения)</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Увеличена вместимость шкафа.</color>"
                                        ],
                                        "ImageSettings": []
                                    },
                                    {
                                        "TextLines": [
                                            "",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Мгновенный крафт.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Апгрейд дома и выбор скина через меню шкафа.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Подробная личная статистика и общая по игрокам, с призами.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Иформация о подключении/отключении игрока.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Обломки кораблей в море.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Информативная панель (кол игроков, время сервера, оповещения, компас, монеты).</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Изменена длительность ночи на сервере(10 минут).</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Добавлены NPC на монументах и рандомно на карте.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Установка картинок на деревянные таблицы.</color>",
                                            "<color=#65DE39> ✓ </color><color=#FFFFFF>Награды за игру, каждые 30 минут.</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Правила сервера</color>",
                                "HeaderText": "<color=#FFFFD7>Правила сервера для игроков</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "<color=#6EE185>-------------------------------Раздел 1.  Cоглашение:-------------------------------</color>",
                                            "<color=#F7A934>1.1.</color><color=#FFFFFF> Правила должны соблюдать все игроки на игровом сервере ScrapLands, а также на сервере Discord.</color>",
                                            "<color=#F7A934>1.2.</color><color=#FFFFFF> Заходя на сервер каждый игрок соглашается с данными правилами.</color>",
                                            "<color=#F7A934>1.3.</color><color=#FFFFFF> Незнание правил не освобождает от ответственности.</color>",
                                            "<color=#F7A934>1.4.</color><color=#FFFFFF> Все возражения о правилах сервера можете высказывать в Дискорд канале #жалобы-репорт</color>",
                                            "<color=#F7A934></color><color=#FFFFFF> Неаргументированные высказывания рассматриваться не будут.</color>",
                                            "<color=#6EE185>-----------------------Раздел 2: Технические правила сервера:-----------------------</color>",
                                            "<color=#F7A934>2.1.</color><color=#FFFFFF> Для точной идентификации администрацией игроков на сервере Discord.</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     Если ник в дискорде отличается от игрового на сервере указать его в скобках:</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     ник diskord [ник игровой на сервере]</color>",
                                            "<color=#F7A934>2.2.</color><color=#FFFFFF> Запрещается использование ников схожих с Администрацией сервера,</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     а также с не персонифицированным ником, таких как Admin, Administrator,</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     Moderator и подобных; использование не читаемых ников.</color>",
                                            "<color=#F7A934>2.3.</color><color=#FFFFFF> Запрещается использование читов и любых иных программ дающее</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     преимущество над другими игроками.</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     Меры пресечения:</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     - Перманентный бан.</color>",
                                            "<color=#F7A934>2.4.</color><color=#FFFFFF> Запрещено обманывать админов (бан навсегда).</color>"
                                        ],
                                        "ImageSettings": []
                                    },
                                    {
                                        "TextLines": [
                                            "<color=#F7A934>2.5.</color><color=#FFFFFF> Запрещается намеренное использование, либо попытка использования багов игры.</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     К ним относятся: использование несовершенство движка игры, механик и тд.</color>",
                                            "<color=#F7A934>2.6.</color><color=#FFFFFF> Запрещен дюп предметов.</color>",
                                            "<color=#F7A934>2.7.</color><color=#FFFFFF> Запрещена продажа/покупка/перепродажа предметов/вещей за реальные деньги.</color>",
                                            "<color=#F7A934>2.8.</color><color=#FFFFFF> Запрещена реклама сторонних проектов.</color>",
                                            "<color=#6EE185>-------------------------Раздел 3: Игровые правила сервера:-------------------------</color>",
                                            "<color=#F7A934>3.1.</color><color=#FFFFFF> Запрещено. Оскорбление игроков, администрации, а также провокации на</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     оскорбление, разжигание межнациональной розни, в общем чате.</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     (Все свои проблемы выясняйте в личке.)</color>",
                                            "<color=#F7A934>3.2.</color><color=#FFFFFF> Запрещен флуд, флуд КАПСОМ в общий чат.</color>",
                                            "<color=#F7A934>3.3.</color><color=#FFFFFF> Запрещено писать или наносить на таблички изображения, запрещенные</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     законодательством, оскорбления, провокации,</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     флаги и другие символики снаружи и т. д.</color>",
                                            "<color=#F7A934>3.4.</color><color=#FFFFFF> Запрещено застраивать по периметру других игроков тем самым блокируя их.</color>",
                                            "<color=#F7A934>3.5.</color><color=#FFFFFF> Запрещено строить дома в текстурах.</color>",
                                            "<color=#F7A934>3.6.</color><color=#FFFFFF> Запрещено выпрашивать / просить админа выдать лут, переместить, и остальная дичь!</color>",
                                            "<color=#F7A934>3.7.</color><color=#FFFFFF> Запрещено проявления расизма в любой форме.</color>",
                                            "<color=#F7A934>3.8.</color><color=#FFFFFF> Запрещено упоминать родителей в любой не моральной форме!</color>"
                                        ],
                                        "ImageSettings": []
                                    },
                                    {
                                        "TextLines": [
                                            "<color=#6EE185>-------------------------------Раздел 4: Администрация:-------------------------------</color>",
                                            "<color=#F7A934>4.1.</color><color=#FFFFFF> Администрация сервера не несет ответственности за пропажу ваших</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     вещей (по причине грабежа) по вашей вине (забыли закрыть дверь, запереть</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     ящик и т.д.)</color>",
                                            "<color=#F7A934>4.2.</color><color=#FFFFFF> Администрация сервера не вмешивается в игровой процесс игроков.(за</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     исключением тестирования на сервере нововведений)</color>",
                                            "<color=#F7A934>4.3.</color><color=#FFFFFF> Администратор имеет право пользоваться своими привилегиями в</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     интересах сервера(ивенты, события, нововведения и т.п).</color>",
                                            "<color=#F7A934>4.4.</color><color=#FFFFFF> Грабители и воришки во время действия режима PVE фиксируются и </color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     подлежат наказанию, бан на 1 день первый раз,</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     бан на 2 недели за повторное нарушение.</color>",
                                            "<color=#F7A934>4.5.</color><color=#FFFFFF> Если не одно из правил не подходит к ситуации Администратор в праве</color>",
                                            "<color=#F7A934></color><color=#FFFFFF>     отреагировать на свое усмотрение.</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Команды для игроков</color>",
                                "HeaderText": "<color=#FFFFD7>Команды доступные для игроков.</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "",
                                            "<color=#1492e6>/info</color><color=#FFFFFF> - Информация о сервере.</color>",
                                            "<color=#1492e6>/kit</color><color=#FFFFFF> - Киты новичкам.</color>",
                                            "",
                                            "<color=#F0E650>Телепорт:</color>",
                                            "<color=#1492e6>/tpr 'имя игрока'</color><color=#FFFFFF> - отправляет запрос игроку на ТП.</color>",
                                            "<color=#1492e6>/tpa 'имя игрока'</color><color=#FFFFFF> - принять запрос на ТП.</color>",
                                            "<color=#1492e6>/tpс 'имя игрока'</color><color=#FFFFFF> - отменить запрос на ТП.</color>",
                                            "<color=#1492e6>/tpb</color><color=#FFFFFF> - вернуться в предыдущее местоположение.</color>",
                                            "<color=#1492e6>/bandit</color><color=#FFFFFF> - телепорт в бандитку.</color>",
                                            "<color=#1492e6>/outpost</color><color=#FFFFFF> - телепорт в город.</color>",
                                            "<color=#F0E650>Точки домов:</color>",
                                            "<color=#1492e6>/home add 'имя дома'</color><color=#FFFFFF> - сохранить точку ТП домой.</color>",
                                            "<color=#1492e6>/home 'имя дома'</color><color=#FFFFFF> - телепортироваться домой.</color>",
                                            "<color=#1492e6>/listhomes</color><color=#FFFFFF> - показать список сохранёных точек ТП домой.</color>",
                                            "<color=#1492e6>/home remove 'имя дома'</color><color=#FFFFFF> - удалить точку дома.</color>",
                                            "<color=#F0E650>Другие команды:</color>",
                                            "<color=#1492e6>/buyraid</color><color=#FFFFFF> - Покупка личной рейд-базы за RP.</color>",
                                            "<color=#1492e6>/craft</color><color=#FFFFFF> - Создать переработчик, миникоптер, седан и др.</color>",
                                            "<color=#1492e6>/ql</color><color=#FFFFFF> - Кодовый замок на карьер, смотреть на карьер и держать замок в руке.</color>",
                                            "<color=#1492e6>/s</color><color=#FFFFFF> - Магазин наград.</color>"
                                        ],
                                        "ImageSettings": []
                                    },
                                    {
                                        "TextLines": [
                                            "",
                                            "<color=#F0E650>Еще команды:</color>",
                                            "<color=#1492e6>/store</color><color=#FFFFFF> - Забрать покупку из магазина overlive.ru.</color>",
                                            "<color=#1492e6>/dronestash</color><color=#FFFFFF> - Прикрепить грузовой отсек на дрон.</color>",
                                            "<color=#1492e6></color><color=#00FF00>            ВИП могут установить замок на грузовой отсек.</color>",
                                            "<color=#1492e6>/myheli</color><color=#00FF00> - Для ВИП вызвать вертолет, сможете сбить вы и ваши друзья.</color>",
                                            "<color=#1492e6>/stat</color><color=#FFFFFF> - Просмотр статистики.</color>",
                                            "<color=#1492e6>/ad</color><color=#FFFFFF> - Включить / выключить автозакрытие дверей.</color>",
                                            "<color=#1492e6>/ad s</color><color=#FFFFFF> - Включить / выключить автозакрытие двери, на которую смотрите.</color>",
                                            "<color=#1492e6>/ad время</color><color=#FFFFFF> - Через сколько закрывать дверь, 5 или 10 секунд.</color>",
                                            "<color=#1492e6>/trade Имя игрока</color><color=#FFFFFF> - Торговля на расстоянии.</color>",
                                            "<color=#1492e6>/trade accept</color><color=#FFFFFF> - Принять запрос торговли.</color>",
                                            "<color=#1492e6>/online</color><color=#FFFFFF> - Список игроков онлайн.</color>",
                                            "<color=#1492e6>/up уровень</color><color=#FFFFFF> - Апгрейд блока, 1 дерево, 2 камень, 3 железо, 4 мвк.</color>",
                                            "<color=#1492e6>/mymini</color><color=#00FF00> - Для ВИП вызвать личный миникоптер.</color>",
                                            "<color=#1492e6>/nomini</color><color=#00FF00> - Для ВИП удалить личный миникоптер.</color>",
                                            "<color=#1492e6>/remove</color><color=#FFFFFF> - Команда для удаления своих строений.</color>",
                                            "<color=#1492e6>/sil адрес-url</color><color=#FFFFFF> - Установить картинку на деревянную таблицу.</color>",
                                            "<color=#1492e6>/skin</color><color=#FFFFFF> - Выбор скина.</color>",
                                            "<color=#1492e6>/reskin</color><color=#FFFFFF> - Рандомный скин.</color>"
                                        ],
                                        "ImageSettings": []
                                    },
                                    {
                                        "TextLines": [
                                            "",
                                            "<color=#F0E650>Еще команды:</color>",
                                            "<color=#1492e6>/bskin</color><color=#FFFFFF> - Включить/выключить скин для каменных построек</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Плагин Виртуальные карьеры</color>",
                                "HeaderText": "<color=#FFFFD7>Виртуальные карьеры</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "<color=#FFFFFF>Команда для управления виртуальными карьерами /qr</color>",
                                            "<color=#FFFFFF>Для постройки виртуального карьера понадобятся геологические заряды и карьер или нефтекачка,</color>",
                                            "<color=#FFFFFF>продается в магазине /s за RP и в интернет магазине overlive.ru</color>",
                                            "<color=#FFFFFF>Если у вас все это есть, то вводите команду и нажимайте +</color>",
                                            "<color=#FFFFFF>Далее при нажатии красной кнопки <color=#FF0000>Искать</color> будет происходить поиск ресурсов,</color>",
                                            "<color=#FFFFFF>если отображаемое значение добычи устраивает, то стройте карьер.</color>",
                                            "",
                                            "<color=#FFFFFF>Производительность и вместительность карьера можно прокачивать за RP.</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Плагин DefendableHomes</color>",
                                "HeaderText": "<color=#FFFFD7>Защити свой дом от волн НПС</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "<color=#FFFFFF>Для начала вам надо найти специальную зеленую, синюю или красную сигнальную шашку.</color>",
                                            "<color=#FFFFFF>Может выпать в одном из ящиков на некоторых монументах или в рейд-базах начиная со средней.</color>",
                                            "<color=#FFFFFF>Подготовить свой дом, укрепить стены, поставить туррели...</color>",
                                            "<color=#FFFFFF>Затем в зоне действия своего шкафа бросить сигнальную шашку.</color>",
                                            "<color=#FFFFFF>Отразите несколько волн НПС и защитите шкаф, НПС будут разрушать дом.</color>",
                                            "<color=#FFFFFF>После отражения атак, вы получите награду.</color>",
                                            "",
                                            "<color=#FFFFFF>Старайтесь выбирать ровную площадку для дома, иначе ивент может не запуститься</color>",
                                            "<color=#FFFFFF>из-за недоступности фундаментов.</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            },
                            {
                                "ButtonText": "<color=#FFFFFF>Большой экскаватор</color>",
                                "HeaderText": "<color=#FFFFD7>Большой экскаватор, особенности</color>",
                                "Pages": [
                                    {
                                        "TextLines": [
                                            "<color=#FFFFFF>Максимальное количество топлива 100 бочек.</color>",
                                            "<color=#FFFFFF>При запуске экскаватора он закрепляется за игроком, запустившим его.</color>",
                                            "<color=#FFFFFF>Добыча защищена от кражи, после остановки у игрока есть 5 минут, чтоб забрать ее.</color>",
                                            "<color=#FFFFFF>Если игрок выйдет из игры более чем на 20 минут, экскаватор остановится.</color>",
                                            "<color=#FFFFFF>Повторный запуск экскаватора тем же игроком возможен через 30 минут.</color>",
                                            "<color=#FFFFFF>Запрещено пользоваться возможными багами экскаватора.</color>"
                                        ],
                                        "ImageSettings": []
                                    }
                                ],
                                "TabButtonAnchor": 4,
                                "TabButtonFontSize": 15,
                                "HeaderAnchor": 0,
                                "HeaderFontSize": 32,
                                "TextFontSize": 15,
                                "TextAnchor": 3,
                                "OxideGroup": ""
                            }
                        ],
                        "ShowInfoOnPlayerInit": true,
                        "ShowInfoOnlyOncePerRuntime": true,
                        "TabToOpenByDefault": 0,
                        "Position": {
                            "MinX": 0.1,
                            "MaxX": 0.9,
                            "MinY": 0.1,
                            "MaxY": 0.8
                        },
                        "BackgroundImage": {
                            "Enabled": true,
                            "Position": {
                                "MinX": 0.0,
                                "MaxX": 1.0,
                                "MinY": 0.0,
                                "MaxY": 1.0
                            },
                            "Url": "http://7-themes.com/data_images/out/35/6889756-black-backgrounds.jpg",
                            "TransparencyInPercent": 10
                        },
                        "ActiveButtonColor": "#07c01a",
                        "InactiveButtonColor": "#1492e6",
                        "CloseButtonColor": "#F20F1E",
                        "CloseButtonText": "Закрыть",
                        "NextPageButtonColor": "#1492e6",
                        "NextPageText": ">>>",
                        "PrevPageButtonColor": "#f6a511",
                        "PrevPageText": "<<<",
                        "BackgroundColor": "#404040",
                        "HelpButton": {
                            "IsEnabled": false,
                            "Text": "<color=#FFFFFF>Информация (клик)</color>",
                            "Position": {
                                "MinX": 0.75,
                                "MaxX": 0.83,
                                "MinY": 0.1,
                                "MaxY": 0.12
                            },
                            "Color": "#3377ff",
                            "FontSize": 13
                        },
                        "UpgradedConfig": false
                    }
                };
                setJsonData(response);
                setEditedData(response);
            } catch (error) {
                console.error("Ошибка загрузки JSON:", error);
            }
        };
        fetchData();
    }, []);

    // Обновление редактируемых полей
    const handleFieldChange = (tabIndex, field, value) => {
        const updatedData = { ...editedData };
        updatedData.settings.Tabs[tabIndex][field] = value;
        setEditedData(updatedData);
    };

    const handleTextLineChange = (tabIndex, pageIndex, lineIndex, value) => {
        const updatedData = { ...editedData };
        updatedData.settings.Tabs[tabIndex].Pages[pageIndex].TextLines[lineIndex] =
            value;
        setEditedData(updatedData);
    };

    // Сохранение изменений
    const saveChanges = async () => {
        try {
            console.log(editedData)
            alert("Изменения сохранены!");
        } catch (error) {
            console.error("Ошибка сохранения JSON:", error);
            alert("Не удалось сохранить изменения.");
        }
    };

    /*if (!jsonData || !editedData) {
        return <Typography>Загрузка...</Typography>;
    }*/

    return (
        <Box p={2}>
            {editedData && editedData.settings.Tabs.map((tab, tabIndex) => (
                <Box key={tabIndex} mb={4}>
                    <Typography variant="h6">Tab {tabIndex + 1}</Typography>
                    <TextField
                        label="ButtonText"
                        fullWidth
                        value={tab.ButtonText}
                        onChange={(e) =>
                            handleFieldChange(tabIndex, "ButtonText", e.target.value)
                        }
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="HeaderText"
                        fullWidth
                        value={tab.HeaderText}
                        onChange={(e) =>
                            handleFieldChange(tabIndex, "HeaderText", e.target.value)
                        }
                        sx={{ mb: 2 }}
                    />
                    {tab.Pages.map((page, pageIndex) => (
                        <Box key={pageIndex} mb={2}>
                            <Typography variant="subtitle1">
                                Страница {pageIndex + 1}
                            </Typography>
                            {page.TextLines.map((line, lineIndex) => (
                                <TextField
                                    key={lineIndex}
                                    label={`TextLine ${lineIndex + 1}`}
                                    fullWidth
                                    multiline
                                    value={line}
                                    onChange={(e) =>
                                        handleTextLineChange(
                                            tabIndex,
                                            pageIndex,
                                            lineIndex,
                                            e.target.value
                                        )
                                    }
                                    sx={{ mb: 1 }}
                                />
                            ))}
                        </Box>
                    ))}
                </Box>
            ))}
            <Button variant="contained" color="primary" onClick={saveChanges}>
                Сохранить
            </Button>
        </Box>
    );
}

export default ConfigEdit;